`<template>
  <div class="container-top">
    <div class="pc container padding-top-60 text-center">
      <div class="h4 main">결제에 실패하였습니다.</div>
      <div class="body2 sub2" style="margin:12px 0 80px 0">{{ $route.query.reason }}</div>
      <div class="flex-center" style="gap:16px">
        <button class="button is-primary" style="width:242px;height:48px" @click="$router.back()">다시 주문하기</button>
        <button class="button is-gray" style="width:242px;height:48px" @click="$router.replace('/home')">홈으로</button>
      </div>
    </div>
    <div class="mobile">
      <div class="mo-container text-center" style="padding-top:30px">
        <div class="h7 main">결제에 실패하였습니다.</div>
        <div class="body4 sub2" style="margin:12px 0 24px 0">{{ $route.query.reason }}</div>
        <div class="flex-center" style="gap:8px">
          <button class="button is-primary" style="width:145px;height:44px" @click="$router.back()">다시 주문하기</button>
          <button class="button is-gray" style="width:145px;height:44px" @click="$router.replace('/home')">홈으로</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "AccountFail",
    created() {

    },
    data() {
      return {}
    },
    methods: {}
  }
</script>

<style lang="stylus" scoped>

</style>
`
